import React from 'react';
import Content from '../components/content';
import Layout from '../components/layout';

const Custom404 = () => {
  return (
    <Layout>
      <Content>No page found.</Content>
    </Layout>
  );
};

export default Custom404;
