import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Content from '../content';

import './Footer.css';

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  );

  const d = new Date();

  return (
    <footer className="footer">
      <Content>
        © 2019-{d.getFullYear()} {data.site.siteMetadata.author}. All rights reserved.
      </Content>
    </footer>
  );
};

export default Footer;
