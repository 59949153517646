import React from 'react';
import cls from 'classnames';

import './Content.css';

const Content = ({ wide, children }) => {
  return <div className={cls('content', { 'content--wide': wide })}>{children}</div>;
};

export default Content;
