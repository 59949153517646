import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import Logo from '../../assets/images/Logo';

import './Header.css';

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <header className="header">
      <Link to="/">
        <Logo className="header__logo" aria-label={data.site.siteMetadata.title} />
      </Link>

      <nav className="header__navigation">
        <ul className="header__navigation-list">
          <li className="header__navigation-list-item">
            <Link to="/speaking/" className="header__navigation-link" activeClassName="header__navigation-link--active">
              Speaking
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
